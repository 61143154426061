import React from "react";
import Copy from "./Copy";

const Footer = () => {
  return (
    <div>
      <Copy />  
    </div>
  );
};

export default Footer;
